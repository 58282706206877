import React,{useState} from 'react'
import { useTheme,makeStyles,withStyles } from '@material-ui/core/styles';
import { hBox,vBox } from "gx-web-ui/utils/flex";
import { useAsyncCall } from 'gx-web-ui/utils/hook'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress';
import PropertyCard from '@src/components/property/PropertyCard'
import ProjectCard from '@src/components/property/ProjectCard'
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Button from '@material-ui/core/Button';
import take from 'lodash/take'
import _ from 'lodash'
import {clone} from '@src/utils/typeCheck'
import {propertySlugGeneratorFromRaw} from '@src/utils/slug'  


import compose from 'recompose/compose'
import {inject,observer} from 'mobx-react'


const AgentListingPanel = (props) => {
    const {agent,appStore,propertyStore,generalPropertyStore,uiStore} = props
    const {checkLogin} = uiStore
    const {
        favorProperty_,
        unfavorProperty_,
    } = propertyStore

    const styles = useStyles()
    const theme = useTheme()
    const [viewMoreOpened, setViewMoreOpened] = useState(false)
    const [listings, setListings] = useState([])

    let { loading, result } = useAsyncCall(
        async ()=> await appStore.clientApi.getProperties_({agent:agent.id}),
        [],
        (res) => {
           const processedData = generalPropertyStore.processListData(res.items).map(item=>propertyStore.additionalProcess(item))
        //    console.log(processedData)
           setListings(processedData)
           return processedData
        }
    );
    


    // const boxShadow = {boxShadow:`0px 4px 10px 0px ${theme.palette.grey[400]}`}

    const onToggleFavor = (listingId,index)=>(newState)=>{
        if(newState===true){
            favorProperty_(listingId)
        }else{
            unfavorProperty_(listingId)
        }
        let cloned = clone(listings)
        cloned[index].processedData.isSaved = newState
        setListings(cloned)
    }
    return (
        <div >
            <Typography className={styles.title}>Current Listings</Typography>
            <div className={styles.listing}>
                {loading ?
                <div className={styles.loading}>
                    <CircularProgress/>
                </div>
                :
                <React.Fragment>
                    <GridList cellHeight={'auto'} cols={3} spacing={16}>
                        {(viewMoreOpened?listings:take(listings,6)).map((data,index)=>
                        <GridListTile cols={1} key={`${data.processedData.isProject?'project':'property'}-${data.rawData.id}`}>
                            {data.processedData.isProject?
                            <ProjectCard 
                                project={data.processedData} 
                                listMode 
                                favored={data.processedData.isSaved}
                                onClick={()=>window.open(`/${propertySlugGeneratorFromRaw(data.rawData)}`,'_blank')}
                                onToggleFavor={checkLogin(onToggleFavor(data.processedData.listingId,index))}
                                isDetailCover={false}
                                listMode={false}
                            />
                            :
                            <PropertyCard 
                                onClick={()=>window.open(`/${propertySlugGeneratorFromRaw(data.rawData)}`,'_blank')}
                                property={data.processedData} 
                                favored={data.processedData.isSaved}
                                onToggleFavor={checkLogin(onToggleFavor(data.processedData.listingId,index))}
                            />
                            }
                        </GridListTile>
                        )}
                    </GridList>
                    {listings.length>6 &&
                    <div className={styles.moreButtonContainer}>
                        <Button variant='outlined' className={styles.btn} onClick={() => setViewMoreOpened(current=>!current)}>
                            {viewMoreOpened?'View less':'View more'}
                        </Button>
                    </div>
                    }
                </React.Fragment>
                }
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme=>({
    title:{
        fontWeight:600,
        fontSize:20
    },
    loading:{
        ...hBox('center','center')
    },
    listingContainer:{
        display:'flex',
        flexWrap: 'wrap',
    },
    card:{
        // width:368
    },
    moreButtonContainer:{
        ...hBox('center','center'),
        marginTop:16
    },
    btn:{
        width:180,
    },
    listing:{
        marginTop:16
    }
}))

export default compose(inject('appStore','propertyStore','generalPropertyStore','uiStore'),observer)(AgentListingPanel)
