import React,{useState} from 'react'
import { useTheme,makeStyles,withStyles } from '@material-ui/core/styles';
import { hBox,vBox } from "gx-web-ui/utils/flex";
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography'
import { typography } from '../../theme/theme';
import Button from '@material-ui/core/Button'
import BlackBtn from '@src/components/common/BlackBtn'
import compose from 'recompose/compose'
import {inject,observer} from 'mobx-react'


import {ReactComponent as Message} from '@src/assets/svgIcons/Message a.svg'
import {ReactComponent as Phone} from '@src/assets/svgIcons/Phone 1.svg'

const AgentInfoPanel = (props) => {
    const { agent, agency, uiStore} = props
    const {setIsMessagePanelVisible, isMessagePanelVisible, setMessageReceiverId, checkLogin, setSentPropertyListing} = uiStore
    const styles = useStyles()
    const [showPhoneNumber, setShowPhoneNumber] = useState(false)

    let mobile = agent.mobile;
    if (mobile.startsWith('+61')) {
        mobile = '0' + mobile.substr(3);
    }
    return (
        <div className={styles.spaceBetween}>
            <div className={styles.alignH}>
                <Avatar src={agent[":user"].avatar} className={styles.avatar}/>
                <div className={styles.agentInfo}>
                    <Typography variant={'h5'}  className={styles.nameText}>{agent.preferredName||agent[':user'].name||''}</Typography>
                    <Typography variant={'body2'} className={styles.subText}>Mobile: {mobile}</Typography>
                    <Typography variant={'body2'} className={styles.subText}>Email: {agent.email}</Typography>
                    <div className={styles.alignH} style={{marginTop:18}}>
                        <Button variant='outlined' className={styles.btn} onClick={() => setShowPhoneNumber(!showPhoneNumber)}>
                            {showPhoneNumber ? mobile : 
                            <>
                            <Phone height={16} width={16} fill='black' style={{marginRight:8}}/>
                            Call
                            </>
                            }
                        </Button>
                        <BlackBtn className={styles.chatBtn} onClick={() => {
                    
                            checkLogin(() => {
                                if (!isMessagePanelVisible) {
                                    setMessageReceiverId(agent[':user'].id)
                                    setSentPropertyListing(false)
                                    window.scroll({top: 0, left: 0, behavior: 'smooth'})
                                }
                                setIsMessagePanelVisible(!isMessagePanelVisible)
                            })()
                        }}>
                            <Message height={16} width={16} fill='white' style={{marginRight:8}}/>
                            Chat
                        </BlackBtn>
                    </div>
                </div>
            </div>
            <div className={styles.agencyInfo}>
                <img src={agency.logo} className={styles.logo} onClick={()=>window.open(`/agency/${agency.id}`,'_blank')}/>
                <Typography variant={'body2'} style={{fontWeight:'bold',fontSize:18}}>{agency.name}</Typography>
                <Typography variant={'body2'} color='textSecondary' style={{fontSize:16}}>{agency[":company"].businessAddress||''}</Typography>
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme=>({
    spaceBetween:{
        ...hBox('center','space-between')
    },
    alignH:{
        ...hBox('center')
    },
    avatar:{
        width: 200,
        height: 200,
    },
    logo:{
        height:55,
        width:'auto',
        marginBottom:4,
        cursor:"pointer",
    },
    agencyInfo:{
        ...vBox('right')
    },
    nameText:{
        fontWeight:'bold',
        fontSize:32
    },
    subText:{
        fontSize:16
    },
    agentInfo:{
        marginLeft:16
    },
    btn:{
        marginRight:16,
        width:168
    },
    chatBtn:{
        width:168
    }
}))

//export default AgentInfoPanel
export default compose(
    inject('uiStore',),
    observer
)(AgentInfoPanel)
