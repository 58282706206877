import React,{useState} from 'react'
import { useTheme,makeStyles,withStyles } from '@material-ui/core/styles';
import { hBox,vBox } from "gx-web-ui/utils/flex";
import Typography from '@material-ui/core/Typography'

import compose from 'recompose/compose'
import {inject,observer} from 'mobx-react'

import {
    isTablet,
    isMobile
} from "react-device-detect";

import AgentInfoPanel from './AgentInfoPanel'
import SaleStatus from './SaleStatus'
import AgentAgencyAbout from './AgentAgencyAbout'
import AgentListingPanel from './AgentListingPanel'

const AgentDetail = (props) => {
    const { agent, agency } = props
    const styles = useStyles()
    const [showPhoneNumber, setShowPhoneNumber] = useState(false)

    let mobile = agent.mobile;
    if (mobile.startsWith('+61')) {
        mobile = '0' + mobile.substr(3);
    }
    const agentName = agent.preferredName||agent[':user'].name||''
    const panels = [
        {
            component:<AgentInfoPanel agency={agency} agent={agent}/>,
            show:true
        },
        {
            component:<SaleStatus 
                        forSale={agent[':statForClient']?.statForSale||0} 
                        sold={agent[':statForClient']?.statSold||0}
                        forRent={agent[':statForClient']?.statForRent||0}
                        leased={agent[':statForClient']?.statLeased||0}/>,
            show:true
        },
        {
            component:<AgentAgencyAbout name={agentName} about={agent.about}/>,
            show:agent.about
        },
        {
            component:<AgentListingPanel agent={agent}/>,
            show:true
        },
    ]
    return (
        <div className={styles.root}>
            <div className={styles.breadCrumb}>
                <Typography variant='body1'>Home > Agents > {agentName}</Typography>
            </div>
            {panels.filter(o=>o.show).map(({component},index)=>
            <React.Fragment key={index}>
                {index!==0 && <div className={styles.divider}/>}
                {component}
            </React.Fragment>
            )}
        </div>
    )
}

const useStyles = makeStyles(theme=>({
    root:{
        margin:32,
        marginLeft:isMobile?16:64,
        marginRight:isMobile?16:64,
    },
    spaceBetween:{
        ...hBox('center','space-between')
    },
    alignH:{
        ...hBox('center')
    },
    breadCrumb:{
        marginBottom:32
    },
    divider:{
        height:1,
        width:'100%',
        backgroundColor:theme.palette.grey[300],
        marginTop:32,
        marginBottom:32,
    },
}))

export default compose(inject('uiStore'),observer)(AgentDetail)
